export const THEME_VARS = {
  p: "primary",
  pt: "primary-text",
  a: "accent",
  at: "accent-text",
  page: "page",
  paget: "page-text",
  paper: "paper",
  error: "error",
  info: "info",
  hint: "hint",
  success: "success",
  warn: "warn",
  skeleton: "skeleton",
  highlight: "highlight",
  cover: "cover",
  "n-200": "n-200",
  "n-50": "n-50",
  "n-700": "n-700",
  disabled: "disabled",
  input: {
    bg: "bg",
    text: "text",
    placeholder: "placeholder",
    touched: "focus",
  },
  button: {
    bg: "bg",
    text: "text",
  },
  label: {
    text: "text",
  },
  alert: {
    bg: "bg",
    text: "text",
    info: "info",
    error: "error",
    success: "success",
    warn: "warn",
  },
  "nav-top": {
    bg: "bg",
    bga: "bg-active",
    text: "text",
    texta: "text-active",
  },
  "nav-side": {
    bg: "bg",
    bga: "bg-active",
    text: "text",
    texta: "text-active",
    divider: "separator",
  },
  select: {
    bg: "bg",
    bga: "bg-active",
    bgh: "bg-hover",
    text: "text",
    texta: "text-active",
    texth: "text-hover",
  },
};
